<template>
  <section>
    <tabs />
  </section>
</template>
<script>
import Tabs from '@/components/Auction/Tabs'

export default {
  name: 'Auction',
  components: {
    Tabs
  }
}
</script>
