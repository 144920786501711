<template>
  <v-row class="auction-feed-grid">
    <template v-if="!loading && items.length">
      <v-col v-for="item in items" cols="12" sm="12" md="6" lg="4" :key="item.id">
        <list-lot-item :item="item" />
      </v-col>
    </template>
    <v-col v-else-if="loading">
      <loader in-block />
    </v-col>
    <v-col v-else>
      <div>
        {{ $t('no_auction_items_found') }}
      </div>
    </v-col>
  </v-row>
</template>
<script>
import ListLotItem from '@/components/Core/Auction/ListLotItem'
import Loader from '@/components/Core/Loader'
import { mapActions } from 'vuex'

export default {
  name: 'AuctionFeedTab',
  components: {
    ListLotItem,
    Loader
  },
  data: () => ({
    items: [],
    loading: true
  }),
  created () {
    this.loading = true
    this.loadFeed()
      .then(result => {
        this.items = result.data
      })
      .finally(() => {
        this.loading = false
      })
  },
  methods: {
    ...mapActions({
      loadFeed: 'auction/loadFeed'
    })
  }
}
</script>
<style scoped>
.auction-feed-grid {
  margin: 50px 30px;
}
</style>
