<template>
  <v-tabs v-model="tab" icons-and-text>
    <v-tabs-slider color="purple darken-4"></v-tabs-slider>
    <v-tab v-for="(tab, i) in tabs" :key="i">
      <v-icon large>{{ tab.icon }}</v-icon>
      <div class="caption py-1">{{ tab.name }}</div>
    </v-tab>
    <v-tab-item>
      <all />
    </v-tab-item>
    <v-tab-item>
      <grower />
    </v-tab-item>
    <v-tab-item>
      <buy />
    </v-tab-item>
    <v-tab-item>
      <sell />
    </v-tab-item>
  </v-tabs>
</template>
<script>
import All from '@/components/Home/Tabs/AuctionFeed'
const Grower = () => import('@/components/Auction/Tabs/Grower')
const Buy = () => import('@/components/Auction/Tabs/Buy')
const Sell = () => import('@/components/Auction/Tabs/Sell')

export default {
  name: 'AuctionTabs',
  components: {
    All,
    Grower,
    Buy,
    Sell
  },
  data: () => ({
    tab: 0,
    tabs: [
      { name: 'All', icon: 'mdi-account' },
      { name: 'Grower', icon: 'mdi-account' },
      { name: 'Buy', icon: 'mdi-account-outline' },
      { name: 'Sell', icon: 'mdi-account-outline' }
    ]
  })
}
</script>
